<template>
  <div class="supportList">
    <van-list v-model="loading" :finished="finished" :finished-text="finishedText" @load="onLoad">
      <div
        type="flex"
        justify="center"
        class="listBox"
        v-for="(item, index) in problems"
        :key="index"
        @click="getDetail(item.PFId)"
      >
        <van-row>
          <van-col span="8">{{$t.support.serviceType}}:</van-col>
          <van-col span="12">{{item.ServiceTypeName}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">{{$t.support.serviceTitle}}:</van-col>
          <van-col span="12">{{item.Title}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">{{$t.support.serviceStatus}}:</van-col>
          <van-col span="12" :class="[item.Status=='1'?'redTxt':'']">{{item.StatusStr}}</van-col>
        </van-row>
        <van-row>
          <van-col span="8">{{$t.support.serviceTime}}:</van-col>
          <van-col span="12">{{item.SubmitTime}}</van-col>
        </van-row>
      </div>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>

<script>
import Placeholder from "@/components/common/Placeholder";
import Vue from "vue";
import { Col, Row,List } from "vant";

Vue.use(Col).use(Row).use(List);
export default {
  name: "SupportList",
  components: {
    Placeholder
  },
  data() {
    return {
      problems: [],
      type: "C",
      pageIndex: 1,
      loading: true,
      finished: false,
      isPlaceholder: false
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    }
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("auth")).userId;
    this.getSupportByPage();
  },
  methods: {
    getDetail(id) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: false,
        path: `user/supportDetail/${id}`,
        name: "SupportDetail",
        query: []
      });
    },
    getSupportByPage() {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.showLoading);
      let param = {};
      param = {
        PageIndex: 1,
        userId: this.userId,
        PlatformType: this.type
      };
      this.$api.system
        .supportByPage(param)
        .then(res => {
          this.loading = false;
          const data = res.data;
          this.problems = data;
          if (data === "" || data === null || data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.isPlaceholder = false;
          }
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    },
    onLoad() {
      let param = {};
      param = {
        PageIndex: this.pageIndex + 1,
        userId: this.userId,
        PlatformType: this.type
      };
      this.$api.system
        .supportByPage(param)
        .then(res => {
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.loading = false;
            this.listMessage = this.listMessage.concat(data);
            this.pageIndex = this.pageIndex + 1;
          }
        })
        .catch(error => {
          this.$commonEnv.commonAction(this.$commonEnv.actionType.hideLoading);
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.supportList {
  margin-top: 3%;
  .listBox {
    margin-bottom: 3%;
    padding: 2% 0;
    background: #ffffff;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    .van-row {
      padding: 1% 3% 1% 3%;
      .van-col:first-child {
        color: #999;
      }
      .van-col {
        line-height: 23px;
      }
      .redTxt {
        color: red;
      }
    }
  }
}
</style>